/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_LOG_SETTINGS_URL } from '../config';
import { convertMillisecondsToSeconds, getIntervalInSeconds } from '../utils';

/**
 * Режимы настроек для отправки логов.
 *
 * @typedef {Object} PostLogSettingsModes
 * @property {string} interval - Режим "interval", при котором задаются только начальная дата и время.
 * @property {string} range - Режим "range", при котором задаются обе границы - начальная и конечная дата и время.
 */

/**
 * Режимы настроек для отправки логов.
 * @type {PostLogSettingsModes}
 */
const POST_LOG_SETTINGS_MODES = {
  interval: 'interval',
  range: 'range'
};

/**
 * Генерирует настройки для режима "interval".
 * @param {Object} settings - Настройки для генерации.
 * @returns {Object} - Настройки для режима "interval".
 */
const getSettingsForIntervalMode = (settings) => {
  console.log('settings', settings);
  const { filterId, dateRange } = settings;
  const { startDate, endDate } = dateRange;

  const settingsForIntervalMode = {
    id: filterId,
    interval: getIntervalInSeconds(startDate, endDate)
  };

  return settingsForIntervalMode;
};

/**
 * Генерирует настройки для режима "range".
 * @param {Object} settings - Настройки для генерации.
 * @returns {Object} - Настройки для режима "range".
 */
const getSettingsForRangeMode = (settings) => {
  const { filterId, dateRange } = settings;
  const { startDate, endDate } = dateRange;

  const settingsForRangeMode = {
    id: filterId,
    start_at: convertMillisecondsToSeconds(startDate),
    stop_at: convertMillisecondsToSeconds(endDate)
  };

  return settingsForRangeMode;
};

/**
 * Фетчер для отправки настроек реактивного лог-фильтра и получение данных для лог-фильтра.
 * В нем только настраивается режим работы ендпоинта и вызывается эндпоинт.
 *
 * @info у эндпоинта 2 режима работы:
 * 'interval' - когда задается только начальная дата и время, а конечная - всегда текущая.
 * 'range' - когда задаются обе границы - startDate и endDate
 * @warn эндпоинт принимает значения в секундах
 *
 * @param {Object} settings - Настройки запроса.
 * @param {Object} settings.dateRange - Диапазон дат для генерации логов.
 * @param {number} settings.dateRange.startDate - Время начала диапазона в миллисекундах.
 * @param {number} settings.dateRange.endDate - Время окончания диапазона в миллисекундах.
 * @param {string} settings.filterId - Идентификатор фильтра для генерации логов.
 *
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {string} [options.mode=POST_LOG_SETTINGS_MODES.range] - Режим запроса.
 * @returns {Promise<Object>} - Обещание с данными меток.
 * @throws {Error} - Ошибка при загрузке меток.
 */
const postReactiveLogsSettingsFetcher = async (settings, options = { mode: POST_LOG_SETTINGS_MODES.range }) => {
  const { mode } = options;

  const convertedSettings = mode === POST_LOG_SETTINGS_MODES.interval
    ? getSettingsForIntervalMode(settings)
    : getSettingsForRangeMode(settings);

  const createQuery = {
    action: 'C',
    items: {
      0: { ...convertedSettings }
    }
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_LOG_SETTINGS_URL, createQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postReactiveLogsSettings.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {Object} settings.dateRange - Диапазон дат для генерации логов.
 * @param {number} settings.dateRange.startDate - Время начала диапазона в миллисекундах.
 * @param {number} settings.dateRange.endDate - Время окончания диапазона в миллисекундах.
 * @param {string} settings.filterId - Идентификатор фильтра для генерации логов.
 *
 * @param {Object} options - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале загрузки.
 * @param {Function} [options.onSuccess] - Callback при успешной загрузке.
 * @param {Function} [options.onError] - Callback при ошибке загрузки.
 * @returns {Promise<Object>} - Обещание с данными логов.
 * @throws {Error} - Ошибка при загрузке логов.
 */
const generateReactiveLog = async (settings, options) => {
  const { onLoading, onSuccess, onError } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    const response = await postReactiveLogsSettingsFetcher(settings);
    const { data, status, description: errorDescription } = response;

    const logInfoList = data.itemslist[0];

    // data.itemslist[0] = {
    //   id:'fsdfsdfsfddfdf',
    //   mac_ip:'192.6.7.5',
    //   filename: "10_1_1_1",
    //   url: 'https://nmstest.wimark.com/files/10_1_1_1',
    //   start_at: 1692102581,
    //   stop_at: 1692102581
    // }

    if (status === 'error') {
      throw new Error(errorDescription);
    }

    if (status === 'success' && onSuccess) {
      onSuccess(logInfoList);
    }

    if (status === 'success') {
      return Promise.resolve(logInfoList);
    }
  } catch (error) {
    onError(error);
    store.commit('setError', error);
  }
};

export default generateReactiveLog;
