<template>
  <WSelect
    md
    :customClass="customClass"
    :id="elementId"
    :label="$t('logs.filter')"
    v-model="formData.searchContainer"
    :disabled="disabled"
    :options="searchContainers"
  />
</template>

<script>
/**
 * Компонент обертка для селектора выбора контейнера для поиска WSelect
 *
 * Нужен с одной единственной целью - побороть особенность ререндеринга этого селектора в Firefox.
 *
 * Дело в том, что в Firefox этот раскрытый селектор перерисовывается каждую секунду (когда обновляется время соседний datepicker) и опция,
 * на которую наведен курсор внутри открытого селектора сбрасывается.
 *
 * Посмотреть как это выглядит можно в видео, которое приложено к задаче https://wimark.kaiten.ru/space/327612/card/37043151
 * https://files.kaiten.ru/26185036-032e-4fe6-a5fe-1f0519288ec3.mkv?name=2024-08-12+07-00-42.mkv&dl=true
 *
 * Тут описана похожая проблема и есть гифка, где можно посмотреть как это выглядит https://github.com/vuejs/vue/issues/7650
 *
 * Это обертка ползволяет решить эту проблему. Альтернативой может являтся
 * навешивание v-once на селектор WSelect в самом ControlPanel и перерисовка его с помощью v-if вручную.
 * Но кажется эта обертка менее костыльный способ, так как не нужно задумыватся о том где и когда вызывать метод для перерисовки с помощью v-if
 *
 * @component
 *
 * ! компонент локальный. Используется только в src/views/Monitor/Logs/components/ControlPanel/ControlPanel.vue
 */
export default {
  name: 'ContainerSelect',
  props: {
    formData: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    searchContainers: {
      type: Array,
      required: true
    },
    customClass: {
      type: [String, Object],
      default: ''
    },
    elementId: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped></style>
