<template>
  <WModalContent
    :isOpen="isOpen"
    @close="handleClose"
  >
    <template #header>
      <h4 class="heading">
        {{ $t('reactiveLogs.modalMACIP') }}
      </h4>
    </template>
    <template #body>
      <div class="body">
        <div class="explanation">
          <small>{{ $t('reactiveLogs.modalMACIPEditInputHint') }}</small>
        </div>
        <WTextarea sm
          v-model.trim="inputsData.mac_ip"
          name="macIp"
          id="macIp"
          cols="30"
          rows="10"
          v-validate="'required|ipOrMacListForReactiveLogsForUpdateExisting|ipOrMacFilterMaxItemsPerStringForReactiveLogs'"
          data-vv-validate-on="input"
          :data-vv-as="$t('reactiveLogs.modalMACIP')"
          :errorText="errors.first('macIp')"
        />
      </div>
    </template>
    <template #footer>
      <div class="button-section">
        <div class="button-section__sub">
          <WButton danger outline @click="handleDelete">
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline @click="handleClose">
            {{ $t('general.cancel') }}
          </WButton>
        </div>
        <WButton success outline @click="handleEdit">
          {{ $t('general.save') }}
        </WButton>
      </div>
    </template>
  </WModalContent>
</template>

<script>
/**
 * компонент - модалка для редактирования/удаления выбранного фильтра для реактивных логов.
 * компонент локальный: используется только в ReactiveLogs.vue
 * @component
 */

export default {
  name: 'EditReactiveLogsFilterModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    /**
     * Объект, представляющий данные лога.
     * @type {Object}
     * @property {string} filename - Имя файла.
     * @property {string} id - Идентификатор.
     * @property {string} mac_ip - MAC/IP адрес.
     * @property {number} start_at - Время начала.
     * @property {number} stop_at - Время окончания.
     * @property {string} url - URL.
     */
    logFilterData: {
      type: Object,
      required: true
    }
  },
  watch: {
    logFilterData: {
      handler(propValue) {
        this.inputsData = propValue;
      },
      deep: true
    }
  },
  data() {
    return {
      inputsData: {
        mac_ip: ''
      }
    };
  },
  methods: {
    async handleEdit() {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid) {
        return;
      }

      this.$emit('edit', this.inputsData);
    },
    handleDelete() {
      this.$emit('delete', this.inputsData.id);
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="css" scoped>
  h4 {
    margin: 0;
  }

  .explanation {
    margin-bottom: 8px;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .button-section__sub > *:first-of-type {
    margin-right: 8px;
  }
</style>
