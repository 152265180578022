import {
  TXT_LOGS_ORDER,
  TXT_LOGS_HIDDEN,
  TXT_LOGS_CELL_BORDERS
} from '../config';

// таблица выгрузки логов в txt

/**
 * Рекурсивная функция для обработки вложенных объектов и массивов.
 * (чтобы их вывести в логи в человеко-читаемом виде. Вложенность может быть какая угодно)
 *
 * @param {any} value - Обрабатываемое значение.
 * @param {number} indent - Отступ для форматирования вложенных значений.
 * @returns {string} - Строка, представляющая отформатированное значение.
 */
const processValue = (value, indent = 0) => {
  if (Array.isArray(value)) {
    return value.map(item => processValue(item, indent + 1)).join('\n');
  }

  if (typeof value === 'object' && value !== null) {
    return Object.entries(value)
      .map(([key, value]) => `${key}: ${processValue(value, indent + 1)}`)
      .join(', ');
  }

  return value;
};

/**
 * Получение столбцов для таблицы с учетом желаемого порядка столбцов и исключения ненужных столбцов
 *
 * @param {Array} dataArr - Массив данных, используемых для определения порядка столбцов.
 * @returns {Array.<string>} - Массив ключей столбцов.
 */
const getColumnKeys = (dataArr) => Array.from(
  new Set(
    [
      ...TXT_LOGS_ORDER,
      ...dataArr.reduce((allKeys, log) => allKeys.concat(Object.keys(log)), [])
    ]
  )
).filter(column => !TXT_LOGS_HIDDEN.includes(column));

/**
 * Функция для формирования содержимого ячейки.
 *
 * @param {any} data - Данные, представляющие содержимое ячейки.
 * @param {[string, string]} cellBorders - Границы ячейки (левая и правая).
 * @param {string} placeholder - Значение по умолчанию, если данные отсутствуют.
 * @returns {string} - Строка, представляющая содержимое ячейки.
 */
const getCell = (data, cellBorders = ['', ''], placeholder = 'no data') => {
  const [leftBorder, rightBorder] = cellBorders;

  return `${leftBorder}${processValue(data).toString() || placeholder}${rightBorder}`;
};

/**
 * Функция для форматирования строки заголовка таблицы.
 *
 * @param {string[]} columns - Массив столбцов.
 * @returns {string[]} - Массив строк заголовка.
 */
const getHeaderRowValues = (columns) => {
  return columns.map(columnKey => getCell(columnKey, TXT_LOGS_CELL_BORDERS));
};

/**
 * Функция для форматирования строки данных таблицы.
 *
 * @param {{
 *   date: string,
 *   id: string,
 *   labels: Array.<{
 *     name: string,
 *     value: string
 *   }>,
 *   showLabels: boolean,
 *   value: string
 * }} logBody - Данные строки.
 * @param {string[]} columns - Массив столбцов.
 * @returns {string[]} - Массив строк данных.
 */
const getBodyRowValues = (logBody, columns) => {
  return columns.map(columnKey => getCell(logBody[columnKey], TXT_LOGS_CELL_BORDERS));
};

/**
 * Функция-генератор текстовой таблицы для логов.
 *
 * @param {Array.<{
 *   date: string,
 *   id: string,
 *   labels: Array.<{
 *     name: string,
 *     value: string
 *   }>,
 *   showLabels: boolean,
 *   value: string
 * }>} tableData - Данные для таблицы.
 * @returns {string} - Строка с текстовым представлением таблицы.
 */
const getTextTable = (tableData) => {
  const columnKeys = getColumnKeys(tableData);

  // шапка таблицы с названиями столбцов
  const getTextHeaderRow = () => getHeaderRowValues(columnKeys)
    .join(', ');

  // строка данных в текстовом формате
  const getTextBodyRow = (logBody, columns) => `${getBodyRowValues(logBody, columns).join(', ')}\n`;

  // body в текстовом формате
  const getTextBodyRows = () => {
    return tableData
      .map(log => getTextBodyRow(log, columnKeys))
      .join('');
  };

  const tableHeader = getTextHeaderRow();
  const tableBody = getTextBodyRows();

  return `${tableHeader}\n\n${tableBody}`;
};

export {
  getTextTable
};
