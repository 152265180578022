/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_UPDATE_DEVICES_URL } from '../config';

/**
 * Функция-фетчер для отправки запроса на обновление реактивных лог-фильтров.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {Object[]} settings.logFilters - Массив объектов фильтров, в которых нужно поменять какое-то поле/поля.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const postUpdateFiltersFetcher = async (settings) => {
  const { logFilters } = settings;

  if (!Array.isArray(logFilters)) {
    throw new Error('settings with logFilters array prop must be set');
  }

  const items = logFilters.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  const updateQuery = {
    action: 'U',
    items
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_UPDATE_DEVICES_URL, updateQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postUpdateDevicesFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {Object[]} settings.logFilters - Массив объектов фильтров точек, в которых нужно поменять какое-то поле/поля.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const updateFilters = async (settings, options) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }
    store.commit('setLoading');

    const response = await postUpdateFiltersFetcher(settings);
    const { data, status, description: errorDescription } = response;

    const { itemslist } = data;

    const updatedFilters = itemslist[0];

    if (status === 'error') {
      throw new Error(errorDescription);
    }

    if (!updatedFilters) {
      throw new Error('updateFilters response returned body without payload');
    }

    if (status === 'success' && onSuccess) {
      onSuccess(updatedFilters);
    }

    if (status === 'success') {
      store.commit('setSuccess', successMessage);
      return Promise.resolve(updatedFilters);
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default updateFilters;
