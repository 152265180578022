/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_GET_DEVICES_URL } from '../config';

/**
 * Функция-фетчер для отправки запроса на получение всех реактивных лог-фильтров и состояния сборов реактивных логов.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {any} settings.location - location.
 * @param {Boolean} settings.withChilds - withChilds.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getAllLogFiltersFetcher = async (settings) => {
  const { location, withChilds } = settings;

  if (!settings) {
    throw new Error('settings with location and withChilds props must be set');
  }

  const getAllDevicesQuery = {
    action: 'R',
    location,
    with_childs: withChilds
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_GET_DEVICES_URL, getAllDevicesQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера getAllDevicesFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {any} settings.location - location.
 * @param {Boolean} settings.withChilds - withChilds.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getLogFilters = async (settings, options) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }
    store.commit('setLoading');

    const response = await getAllLogFiltersFetcher(settings);
    const { data, status, description: errorDescription } = response;

    const { itemslist } = data;

    const devicesReactiveLogFilters = itemslist[0].troubleshooting_filters;
    const reactiveLogsState = itemslist[0].state;

    if (status === 'error') {
      throw new Error(errorDescription);
    }

    if (!devicesReactiveLogFilters || !reactiveLogsState) {
      throw new Error('getDevices response returned body without payload');
    }

    if (status === 'success' && onSuccess) {
      onSuccess({
        devicesReactiveLogFilters,
        reactiveLogsState
      });
    }

    if (status === 'success') {
      store.commit('setSuccess');
      return Promise.resolve({
        devicesReactiveLogFilters,
        reactiveLogsState
      });
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default getLogFilters;
