import { TXT_LOGS_ORDER, TXT_LOGS_HIDDEN, TXT_LOGS_CELL_BORDERS } from './txtLogs';
import { PREDEFINED_CONTAINERS } from './containers';
import { NOT_DISPALYED_FILTER_ITEMS_FOR_ONE, NOT_DISPALYED_FILTER_ITEMS_FOR_WLC } from './notDisplayedFilterItemsForBuilds';

function getDefaultFormValues() {
  const getStartDate = () => Date.now() - (15 * 60 * 1000);
  const getEndDate = () => Date.now();

  return {
    limit: 100,
    searchText: '',
    searchContainer: '',
    // по умолчанию - временной диапазон - 15 мин
    startDate: getStartDate(),
    endDate: getEndDate()
  };
}

function getFormValuesForByClientMACSearch(clientMac) {
  return {
    limit: 100,
    searchText: clientMac.toLowerCase(),
    searchContainer: '',
    // для поиска по MAC'у нужен временной диапазон за весь день
    startDate: new Date().setHours(0, 0, 0, 0),
    endDate: new Date().setHours(23, 59, 59, 999)
  };
}


export {
  TXT_LOGS_ORDER,
  TXT_LOGS_HIDDEN,
  TXT_LOGS_CELL_BORDERS,
  NOT_DISPALYED_FILTER_ITEMS_FOR_ONE,
  NOT_DISPALYED_FILTER_ITEMS_FOR_WLC,
  PREDEFINED_CONTAINERS,
  getDefaultFormValues,
  getFormValuesForByClientMACSearch
};
