import { API_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const POST_LOG_SETTINGS_URL = `${API_URL}/api/troubleshooting/generate`;
const POST_ADD_DEVICES_URL = `${API_URL}/api/troubleshooting/filters`;
const POST_UPDATE_DEVICES_URL = `${API_URL}/api/troubleshooting/filters`;
const POST_DELETE_DEVICES_URL = `${API_URL}/api/troubleshooting/filters`;
const POST_GET_DEVICES_URL = `${API_URL}/api/troubleshooting/troubleshooting_shema`;
const POST_REACTIVE_LOGS_STATE_URL = `${API_URL}/api/troubleshooting/troubleshooting_shema`;

export {
  AXIOS_INSTANCE,
  POST_GET_DEVICES_URL,
  POST_LOG_SETTINGS_URL,
  POST_ADD_DEVICES_URL,
  POST_UPDATE_DEVICES_URL,
  POST_DELETE_DEVICES_URL,
  POST_REACTIVE_LOGS_STATE_URL
};
