<template>
  <div>
    <div class="d-flex" :class="{'visible':generateStatusOpened}">
      <div class="card left">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <WButton info @click="openAddModal">
              Add
            </WButton>
            <WButton info>
              Delete
            </WButton>
            <WButton info>
              Start
            </WButton>
            <WButton info>
              Stop
            </WButton>
          </div>
        </div>
        <div class="card-block">
          <table class="table">
            <thead>
            <tr>
              <th><input type="checkbox" class="form-check m-0"></th>
              <th>MAC/IP Address</th>
              <th>Trace file</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><input type="checkbox" class="form-check m-0"></td>
              <td>127.0.0.1</td>
              <td>log.txt</td>
              <td>
                <WButton success sm
                  @click="openGenerateModal"
                >
                  Generate
                </WButton>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between">
            <nav>
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
            <div class="d-flex align-items-center per-page-wrapper">
              <select id="per-page" class="form-control">
                <option>10</option>
              </select>
              <label for="per-page" class="mb-0 ml-1">items per page</label>
            </div>
          </div>
        </div>
      </div>
      <div class="card ml-2 right" v-if="generateStatusOpened">
        <div class="card-header">
          <WButton secondary sm
            @click="closeGenerationStatus"
          >
            <i class="fa fa-close"></i>
          </WButton>
        </div>
        <div class="card-block">
          <div class="row">
            <dd class="col-6">State</dd>
            <dd class="col-6" v-if="!generateStatusEnd">Logs generation in progress</dd>
            <dd class="col-6" v-else>Successful <a href="javascript:void(0)" @click="openLogDetailsModal">See details</a></dd>
          </div>
          <div class="row">
            <dd class="col-6">MAC/IP Address</dd>
            <dd class="col-6"><a href="javascript:void(0)">127.0.0.1</a></dd>
          </div>
          <div class="row">
            <dd class="col-6">Start time</dd>
            <dd class="col-6">2023-06-27 23:59</dd>
          </div>
          <div class="row">
            <dd class="col-6">End time</dd>
            <dd v-if="!generateStatusEnd" class="col-6">-</dd>
            <dd v-else class="col-6">2023-06-27 00:00</dd>
          </div>
          <div v-if="generateStatusEnd" class="row">
            <dd class="col-6">Trace file</dd>
            <dd class="col-6"><a href="javascript:void(0)">Download</a></dd>
          </div>
        </div>
      </div>
    </div>
    <Modal
        title="Manage syslog configuration"
        class="modal-primary"
        v-model="generateModalOpened"
        okText="Confirm"
        cancelText="Cancel"
        @ok="handleOk"
        @cancel="handleCancel"
        effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">Enter time interval</h4>

      <div class="container container-fluid">
          <h6>Log level settings</h6>
          <hr>
          <div class="form-group row align-items-center">
            <label for="internal-logs" class="col-6 col-form-label">Enable internal logs</label>
            <div class="col-6">
              <input type="checkbox" id="internal-logs" value="">
            </div>
          </div>
          <div class="form-group row">
            <label for="syslog" class="col-6 col-form-label">Generate logs for last</label>
            <div class="col-6">
              <div class="mb-1">
                <input type="radio" name="time" id="10m" value="10m" checked>
                <label class="mb-0 ml-1" for="10m">
                  10 minutes
                </label>
              </div>
              <div class="mb-1">
                <input type="radio" name="time" id="30m" value="30m" checked>
                <label class="mb-0 ml-1" for="30m">
                  30 minutes
                </label>
              </div>
              <div>
                <input type="radio" name="time" id="1h" value="1h" checked>
                <label class="mb-0 ml-1" for="1h">
                  30 minutes
                </label>
              </div>
            </div>
          </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary @click="handleGenerate">
          Generate
        </WButton>
        <WButton danger outline>
          Cancel
        </WButton>
      </div>
    </Modal>
    <Modal
        title="Manage syslog configuration"
        class="modal-primary"
        v-model="addModalOpened"
        okText="Confirm"
        cancelText="Cancel"
        @ok="handleOk"
        @cancel="handleCancel"
        effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">Add MAC/IP address</h4>

      <div class="container container-fluid">
        <div class="form-group row align-items-center">
          <label for="internal-logs" class="col-4 col-form-label">MAC / IP Address</label>
          <div class="col-8">
            <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Enter MAC/IP address every new line">
            </textarea>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary>
          Apply
        </WButton>
        <WButton danger outline>
          Cancel
        </WButton>
      </div>
    </Modal>
    <Modal
        title="Manage syslog configuration"
        class="modal-primary"
        v-model="logDetailsModalOpened"
        okText="Confirm"
        cancelText="Cancel"
        @ok="handleOk"
        @cancel="handleCancel"
        effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">Trace output</h4>
      <div class="p-1 logs-wrapper">
          [2023-06-27 10:00:00] INFO: Application started.<br>
          [2023-06-27 10:00:05] INFO: User login request received. Username: johndoe<br>
          [2023-06-27 10:00:07] INFO: User authentication successful. Username: johndoe<br>
          [2023-06-27 10:01:12] INFO: Search query received. Keyword: "trace example"<br>
          [2023-06-27 10:01:15] INFO: Searching database for relevant results. Keyword: "trace example"<br>
          [2023-06-27 10:01:18] INFO: 10 results found for keyword "trace example".<br>
          [2023-06-27 10:01:20] INFO: Rendering search results for user. Keyword: "trace example"<br>
          [2023-06-27 10:02:30] INFO: User selected result. Result ID: 2356<br>
          [2023-06-27 10:02:35] INFO: Fetching details for result. Result ID: 2356<br>
          [2023-06-27 10:02:40] INFO: Result details retrieved successfully. Result ID: 2356<br>
          [2023-06-27 10:03:00] INFO: User added result to favorites. Result ID: 2356<br>
          [2023-06-27 10:03:05] INFO: Saving favorite result for user. Result ID: 2356<br>
          [2023-06-27 10:03:10] INFO: Favorite result saved successfully. Result ID: 2356<br>
          [2023-06-27 10:04:00] INFO: User initiated download for result. Result ID: 2356<br>
          [2023-06-27 10:04:05] INFO: Download request sent to server. Result ID: 2356<br>
          [2023-06-27 10:04:08] INFO: Download started. Result ID: 2356<br>
          [2023-06-27 10:04:30] INFO: Download completed successfully. Result ID: 2356<br>
          [2023-06-27 10:05:00] INFO: User logged out. Username: johndoe<br>
          [2023-06-27 10:05:03] INFO: User session closed. Username: johndoe<br>
          [2023-06-27 10:05:05] INFO: Application shutdown.
      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary @click="closeLogDetailsModal">
          Close
        </WButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../components/Modal.vue';

export default {
  name: 'PortalAdsNew',
  components: {
    Modal
    // TablePaginationV2,
  },
  inject: ['$validator'],
  filters: {
  },
  data() {
    return {
      generateModalOpened: false,
      addModalOpened: false,
      generateStatusOpened: false,
      generateStatusEnd: false,
      logDetailsModalOpened: false
    };
  },
  mounted() {
  },
  methods: {
    handleCancel() {},
    handleOk() {},
    handleGenerate() {
      this.generateModalOpened = false;
      this.generateStatusOpened = true;
      this.generateStatusEnd = false;
      setTimeout(() => {
        this.generateStatusEnd = true;
      }, 3000);
    },
    openAddModal() {
      this.addModalOpened = true;
    },
    openGenerateModal() {
      this.generateModalOpened = true;
    },
    openGenerationStatus() {
      this.generateStatusOpened = true;
    },
    closeGenerationStatus() {
      this.generateStatusOpened = false;
    },
    openLogDetailsModal() {
      this.logDetailsModalOpened = true;
    },
    closeLogDetailsModal() {
      this.logDetailsModalOpened = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.logs-wrapper{
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}
.per-page-wrapper{
  select{
    width: 100px;
  }
  label{
    width: 100px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}
.card.left {
  transition: width 0.2s;
  width: 100%;
}
.visible .card.left{
  width: 50%;
}
.visible .card.right{
  transition: width 0.2s;
  width: 50%;
}
.logs-wrapper{
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}
</style>
