<template>
  <WTable
    style="height: 75vh"
    max-height="calc(75vh - 180px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :select-options="{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    :search-options="{
      enabled: true,
      skipDiacritics: true,
      searchFn: globalSearchFn,
      placeholder: `${$t('general.search')} ${$t('reactiveLogs.tableMACIP')}`
    }"
    :pagination-options="{
      enabled: true,
      remoteMode: true,
      mode: 'records', // pages, records
      position: 'bottom',
      perPage: 10,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'file'">
        <div class="d-flex" v-if="props.row.file.url">
          <ReactiveLogsTooltip :fileName="props.row.file.filename" :href="API_URL + props.row.file.url"
            :startAt="props.row.file.start_at" :stopAt="props.row.file.stop_at" :url="props.row.file.url" />
        </div>
        <div v-else>-</div>
      </template>

      <template v-else-if="props.column.field === 'action'">
        <WButton success sm :disabled="isDisabled"
          @click.stop.prevent="$emit('on-generate-report', props.row.action.id)">
          {{ $t('reactiveLogs.generateButton') }}
        </WButton>
      </template>

      <span v-else>
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
  </WTable>
</template>

<script>
/**
 * компонент - таблица фильтров реактивных логов.
 * компонент локальный: используется только в ReactiveLogs.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import { API_URL } from '@/config';

import {
  generateTableColumns,
  generateTableRows
} from './config';

import {
  ReactiveLogsTooltip
} from './components';

export default {
  name: 'LogFiltersTable',
  components: {
    ReactiveLogsTooltip
  },
  props: {
    logFilters: {
      type: Array,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOneColMode: this.isDisabled,
      isTableColumnHidden: {
        mac_ip: false,
        file: false,
        action: false
      }
    };
  },
  watch: {
    isDisabled(newVal) {
      this.isOneColMode = newVal;
    }
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            mac_ip: this.isTableColumnHidden.mac_ip,
            file: this.isTableColumnHidden.file || this.isOneColMode,
            action: this.isTableColumnHidden.action || this.isOneColMode
          }
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        logFiltersData: this.logFilters
      });
    }
  },
  methods: {
    globalSearchFn(row, col, cellValue, searchQuery) {
      // https://xaksis.github.io/vue-good-table/guide/configuration/search-options.html#searchfn

      // поиск только по колонке mac_ip
      // игнорируются символы .,' ' (точка, запятая, пробел)

      if (col.field !== 'mac_ip') {
        return false;
      }

      const searchPattern = /[ ,.:]/g;

      const formattedSearchQuery = searchQuery.replace(searchPattern, '').toLowerCase();
      const formattedRowMacIpValue = row.mac_ip.replace(searchPattern, '').toLowerCase();

      return formattedRowMacIpValue.includes(formattedSearchQuery);
    },
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;

      const nativeData = selectedRows.map(el => {
        return el.nativeData;
      });

      this.$emit('on-selected-rows-change', nativeData);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    }
  },
  created() {
    this.API_URL = API_URL;
  }
};
</script>

<style lang="css" scoped>
</style>
