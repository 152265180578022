import { v4 as uuidv4 } from 'uuid';

/**
 * конфигурация таблицы LogsTable
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/
function generateTableColumns({ i18nInstance, hiddenColumnRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('general.date'),
      field: 'date',
      type: 'date',
      dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
      dateOutputFormat: 'yyyy-MM-dd HH:mm:ss',
      width: '20%',
      hidden: hiddenColumnRules.date
    },
    {
      label: $t('logs.value'),
      field: 'log',
      type: 'text',
      width: '80%',
      sortable: false,
      hidden: hiddenColumnRules.log
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ logsData }) {
  return logsData.map(log => {
    const {
      date, id, labels, value
    } = log;

    const children = [];
    const child = {
      id: uuidv4(),
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: log,
      date: null,
      log: labels
    };

    children.push(child);

    return {
      id,
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: log,
      date,
      log: value,
      children
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
