/**
 * Конфиг компонента GenerateReactiveLogsModal.
*/

/**
 * Конфигурация интервалов времени и их множителей для кастомной радио.
 * @typedef {Object} CustomIntervalConfig
 * @property {string} label - Метка интервала.
 * @property {number} multiplier - Множитель для преобразования в миллисекунды.
 */
const CUSTOM_INTERVALS_UNITS_CONFIG = {
  seconds: {
    label: 'seconds',
    multiplier: 1000
  },
  minutes: {
    label: 'minutes',
    multiplier: 60 * 1000
  },
  hours: {
    label: 'hours',
    multiplier: 60 * 60 * 1000
  }
};

/**
 * Конфигурация радио-опций для управления временными интервалами.
 * @typedef {Object} RadioOptionConfig
 * @property {string} label - Метка опции.
 * @property {function} getIntervalTimestamp - Функция для получения временного интервала в миллисекундах.
 * @property {function} getStartDate - Функция для получения даты начала интервала.
 * @property {function} getEndDate - Функция для получения даты окончания интервала.
 */
const RADIO_OPTIONS_CONFIG = {
  '10min': {
    label: '10min',
    getIntervalTimestamp() {
      return 10 * 60 * 1000;
    },
    getStartDate() {
      return Date.now() - this.getIntervalTimestamp();
    },
    getEndDate() {
      return Date.now();
    }
  },
  '30min': {
    label: '30min',
    getIntervalTimestamp() {
      return 30 * 60 * 1000;
    },
    getStartDate() {
      return Date.now() - this.getIntervalTimestamp();
    },
    getEndDate() {
      return Date.now();
    }
  },
  '1hour': {
    label: '1hour',
    getIntervalTimestamp() {
      return 60 * 60 * 1000;
    },
    getStartDate() {
      return Date.now() - this.getIntervalTimestamp();
    },
    getEndDate() {
      return Date.now();
    }
  },
  customTimeRange: {
    label: 'customTimeRange',
    getIntervalTimestamp(customTimeRangeInputs) {
      const { amount, units } = customTimeRangeInputs;

      const currentUnitsConfig = CUSTOM_INTERVALS_UNITS_CONFIG[units];

      return amount * currentUnitsConfig.multiplier;
    },
    getStartDate(customTimeRangeInputs) {
      return Date.now() - this.getIntervalTimestamp(customTimeRangeInputs);
    },
    getEndDate() {
      return Date.now();
    }
  },
  customDatetimeRange: {
    label: 'customDatetimeRange',
    getIntervalTimestamp: null, // задается динамически из datepicker
    getStartDate: null, // задается динамически из datepicker
    getEndDate: null // задается динамически из datepicker
  }
};

export {
  CUSTOM_INTERVALS_UNITS_CONFIG,
  RADIO_OPTIONS_CONFIG
};
