/**
 * Миксин для обработки генерации отчета.
 *
 * !!! миксин локальный: используется только в ReactiveLogs.vue
 *
 * @mixin
 *
*/

import { PROMISE_STATUSES } from './config';

const {
  idle,
  loading,
  success,
  error
} = PROMISE_STATUSES;

const reactiveLogsGeneratingStateMixin = {
  data() {
    return {
      reactiveLogsGeneratingState: idle
    };
  },
  methods: {
    setReactiveLogsGeneratingState(state) {
      if (!Object.values(PROMISE_STATUSES).includes(state)) {
        throw new Error("new state must be one of next values: 'idle', 'loading', 'success', 'error'");
      }

      this.reactiveLogsGeneratingState = state;
    }
  },
  computed: {
    isReactiveLogsGeneratingStateIdle() {
      const isIdle = this.reactiveLogsGeneratingState === idle;

      return isIdle;
    },
    isReactiveLogsGeneratingStateLoading() {
      const isLoading = this.reactiveLogsGeneratingState === loading;

      return isLoading;
    },
    isReactiveLogsGeneratingStateSuccess() {
      const isSuccess = this.reactiveLogsGeneratingState === success;

      return isSuccess;
    },

    isReactiveLogsGeneratingStateError() {
      const isError = this.reactiveLogsGeneratingState === error;

      return isError;
    }
  }
};

export default reactiveLogsGeneratingStateMixin;
