const PROMISE_STATUSES = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  error: 'error'
};

export {
  PROMISE_STATUSES
};
