/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_DELETE_DEVICES_URL } from '../config';

/**
 * Функция для отправки запроса на удаление реактивных лог-фильтров для устройств и получения ответа.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {string[]} settings.filterIds - Массив идентификаторов фильтров для удаления.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const postDeleteFiltersFetcher = async (settings) => {
  const { filterIds } = settings;

  if (!Array.isArray(filterIds)) {
    throw new Error('deviceIds prop must be an array of ids');
  }

  const items = filterIds.reduce((acc, curr) => {
    acc[curr] = '';
    return acc;
  }, {});

  const deleteQuery = {
    action: 'D',
    items: { ...items }
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_DELETE_DEVICES_URL, deleteQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error.message);
  }
};

/**
 * фукнция-обработчик фетчера postDeleteFiltersFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {string[]} settings.filterIds - Массив идентификаторов фильров устройств для удаления.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const deleteFilters = async (settings, options) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }
    store.commit('setLoading');

    const response = await postDeleteFiltersFetcher(settings);
    const { data, status, description } = response;

    if (status === 'error') {
      throw new Error(description);
    }

    if (status === 'success' && onSuccess) {
      onSuccess();
    }

    if (status === 'success') {
      store.commit('setSuccess', description || successMessage);

      return Promise.resolve(data);
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default deleteFilters;
