<template>
  <div>
    <div style="display: flex;">
      <Transition>
        <div class="card left">
          <div class="card-header">
            <div>{{$t('sidebarNew.reactiveLogs')}}</div>
            <hr>
            <div class="d-flex flex-column">
              <div class="mb-1">
                <p class="mb-0 ml-0">
                  {{ $t('reactiveLogs.debugStateCaption') }} :
                  <span v-if="isReactiveLogsStart" class="badge badge-success">
                    {{ $t('reactiveLogs.debugStateStrated') }}
                  </span>
                  <span v-else class="badge badge-danger">{{ $t('reactiveLogs.debugStateStopped') }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="card-block">
            <LogFiltersTable
              :logFilters="logFilters"
              :isDataLoading="false"
              :isDisabled="isReactiveLogsGeneratingStateLoading"
              @on-generate-report="openGenerateReportModal"
              @on-selected-rows-change="handleSelectedRows"
              @on-cell-click="handleRowClick"
            >
              <template v-slot:table-menu>
                <div class="control-panel">
                  <div class="control-panel__container">
                    <WButton info
                      :disabled="isAddButtonDisabled"
                      @click="openFilterAddModal">
                      {{ $t('reactiveLogs.addButton') }}
                    </WButton>
                    <WButton info
                      :disabled="isDeleteButtonDisabled"
                      @click="openDeleteFiltersModal">
                      {{ $t('reactiveLogs.deleteButton') }}
                    </WButton>
                  </div>
                  <div class="control-panel__container">
                    <WButton info
                      :disabled="isStartButtonDisabled"
                      @click="startDebug">
                      {{ $t('reactiveLogs.startButton') }}
                    </WButton>
                    <WButton info
                      :disabled="isStopButtonDisabled"
                      @click="stopDebug">
                      {{ $t('reactiveLogs.stopButton') }}
                    </WButton>
                  </div>
                </div>
              </template>
            </LogFiltersTable>
          </div>
        </div>
      </Transition>

      <ReactiveLogsGeneratingStatusCard
        :isOpen="isGeneratingStatusCardOpen"
        :isLoading="isReactiveLogsGeneratingStateLoading"
        :isSuccess="isReactiveLogsGeneratingStateSuccess"
        :isError="isReactiveLogsGeneratingStateError"
        :generatingResultData="generatingResultData"
        @close="closeGeneratingStatusCard"
      />
    </div>

    <GenerateReactiveLogsModal
      :isOpen="isGenerateReportModalOpen"
      @close="closeGenerateReportModal"
      @sumbit="handleGenerateReport"
    />

    <EditReactiveLogsFilterModal
      :isOpen="isFilterEditModalOpen"
      :logFilterData="logFilterToEdit"
      @close="closeFilterEditModal"
      @edit="handleEditFilter"
      @delete="openDeleteFilterModal"
    />

    <AddReactiveLogsFilterModal
      :isOpen="isFilterAddModalOpen"
      @close="closeFilterAddModal"
      @add="handleAddFilter"
    />

    <DeleteReactiveLogsFiltersModal
      :isOpen="isDeleteFiltersModalOpen"
      @close="closeDeleteFiltersModal"
      @confirm="handleDeleteMultipleFilters"
    />

    <DeleteReactiveLogsFiltersModal
      :isOpen="isDeleteFilterModalOpen"
      @close="closeDeleteFilterModal"
      @confirm="handleDeleteFilter"
    />
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
import reactiveLogsApi from './api';

import {
  GenerateReactiveLogsModal,
  EditReactiveLogsFilterModal,
  AddReactiveLogsFilterModal,
  DeleteReactiveLogsFiltersModal,
  ReactiveLogsGeneratingStatusCard,
  LogFiltersTable
} from './components';

import { reactiveLogsGeneratingStateMixin } from './mixins';

const REACTIVE_LOGS_STATES = {
  start: 'start',
  stop: 'stop'
};

export default {
  name: 'ReactiveLogs',
  components: {
    GenerateReactiveLogsModal,
    EditReactiveLogsFilterModal,
    AddReactiveLogsFilterModal,
    DeleteReactiveLogsFiltersModal,
    ReactiveLogsGeneratingStatusCard,
    LogFiltersTable
  },
  mixins: [reactiveLogsGeneratingStateMixin],
  inject: ['$validator'],
  filters: {},
  data() {
    return {
      logFilters: [],
      currentLogFilterId: null,
      reactiveLogsState: undefined,
      logFilterToEdit: {},
      generatingResultData: {},
      checkedFilterIds: [],
      isGenerateReportModalOpen: false,
      isFilterEditModalOpen: false,
      isConfirmModalOpen: false,
      isFilterAddModalOpen: false,
      isDeleteFiltersModalOpen: false,
      isDeleteFilterModalOpen: false,
      isGeneratingStatusCardOpen: false
    };
  },
  computed: {
    isReactiveLogsStart() {
      const isStart = this.reactiveLogsState === this.REACTIVE_LOGS_STATES.start;
      return isStart;
    },
    isReactiveLogsStop() {
      const isStop = this.reactiveLogsState === this.REACTIVE_LOGS_STATES.stop;
      return isStop;
    },
    areLogFiltersEmpty() {
      return !this.logFilters.length;
    },
    isAddButtonDisabled() {
      const isDisabled = this.isReactiveLogsStart
        || this.isReactiveLogsGeneratingStateLoading;

      return isDisabled;
    },
    isStartButtonDisabled() {
      const isDisabled = this.isReactiveLogsStart
        || this.isReactiveLogsGeneratingStateLoading
        || this.areLogFiltersEmpty;

      return isDisabled;
    },
    isStopButtonDisabled() {
      const isDisabled = this.isReactiveLogsStop
        || this.isReactiveLogsGeneratingStateLoading
        || this.areLogFiltersEmpty;

      return isDisabled;
    },
    isDeleteButtonDisabled() {
      const isDisabled = !this.checkedFilterIds.length
        || this.isReactiveLogsStart
        || this.isReactiveLogsGeneratingStateLoading;

      return isDisabled;
    }
  },
  methods: {
    handleSelectedRows(selectedRows) {
      this.checkedFilterIds = selectedRows.map(row => {
        return row.id;
      });
    },
    handleRowClick(data) {
      this.openFilterEditModal(data.row.nativeData);
    },
    openFilterAddModal() {
      this.isFilterAddModalOpen = true;
    },
    closeFilterAddModal() {
      this.isFilterAddModalOpen = false;
    },
    openDeleteFiltersModal() {
      this.isDeleteFiltersModalOpen = true;
    },
    closeDeleteFiltersModal() {
      this.isDeleteFiltersModalOpen = false;
    },
    openDeleteFilterModal() {
      this.isDeleteFilterModalOpen = true;
    },
    closeDeleteFilterModal() {
      this.isDeleteFilterModalOpen = false;
    },
    openFilterEditModal(currentFilter) {
      if (this.isReactiveLogsStart
        || this.isReactiveLogsGeneratingStateLoading) {
        return;
      }

      this.closeGeneratingStatusCard();
      this.logFilterToEdit = { ...currentFilter };
      this.isFilterEditModalOpen = true;
    },
    closeFilterEditModal() {
      this.logFilterToEdit = {};

      this.isFilterEditModalOpen = false;
    },
    openGenerateReportModal(logFilterId) {
      this.closeGeneratingStatusCard();

      this.currentLogFilterId = logFilterId;
      this.isGenerateReportModalOpen = true;
    },
    closeGenerateReportModal() {
      this.isGenerateReportModalOpen = false;
    },
    openGeneratingStatusCard() {
      this.isGeneratingStatusCardOpen = true;
    },
    closeGeneratingStatusCard() {
      if (this.isReactiveLogsGeneratingStateLoading) {
        return;
      }

      this.isGeneratingStatusCardOpen = false;
      this.setReactiveLogsGeneratingState('idle');
    },
    resetCheckedFilters() {
      this.checkedFilterIds = [];
    },
    getAllLogFilters() {
      reactiveLogsApi.getLogFilters({
        location: this.$store.getters.getDefaultLocationForRequests(this),
        withChilds: this.$store.getters.getDefaultWithChildsForRequests(this)
      }, {
        onLoading: () => {
          this.closeGeneratingStatusCard();
          this.logFilters = [];
        },
        onSuccess: (data) => {
          const { devicesReactiveLogFilters, reactiveLogsState } = data;

          this.logFilters = devicesReactiveLogFilters;
          this.reactiveLogsState = reactiveLogsState;
        }
      });
    },
    updateLogFilterData(filterId, logInfo) {
      const logItemForAddIndex = this.logFilters.findIndex((item) => item.id === filterId);
      this.logFilters.splice(logItemForAddIndex, 1, logInfo);
    },
    async handleGenerateReport(dateRange) {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid) {
        return;
      }

      reactiveLogsApi.generateReactiveLog({
        filterId: this.currentLogFilterId,
        dateRange
      }, {
        onLoading: () => {
          this.setReactiveLogsGeneratingState('loading');
          this.openGeneratingStatusCard();
          this.generatingResultData = {};
          this.closeGenerateReportModal();
        },
        onSuccess: (updatedFilterData) => {
          this.setReactiveLogsGeneratingState('success');
          this.generatingResultData = updatedFilterData;
          this.updateLogFilterData(this.currentLogFilterId, updatedFilterData);
          this.currentLogFilterId = null;
        },
        onError: () => {
          this.setReactiveLogsGeneratingState('error');
          this.currentLogFilterId = null;
        }
      });
    },
    async handleAddFilter(macAdresses) {
      reactiveLogsApi.addFilters({
        ipMACs: macAdresses
      }, {
        onSuccess: () => {
          this.getAllLogFilters();
          this.closeFilterAddModal();
        },
        successMessage: this.$t('reactiveLogs.notificationCreatedSuccessfully')
      });
    },
    handleEditFilter(editedLogFilter) {
      reactiveLogsApi.updateFilters({
        logFilters: [editedLogFilter]
      }, {
        onSuccess: () => {
          this.getAllLogFilters();
          this.closeFilterEditModal();
        },
        successMessage: this.$t('reactiveLogs.notificationUpdatedSuccessfully')
      });
    },
    handleDeleteFilter() {
      const reactiveLogsFilterIdToDelete = this.logFilterToEdit.id;

      reactiveLogsApi.deleteFilters({
        filterIds: [reactiveLogsFilterIdToDelete]
      }, {
        onSuccess: () => {
          this.closeFilterEditModal();
          this.closeDeleteFilterModal();
          this.getAllLogFilters();
        },
        onError: () => {
          this.closeFilterEditModal();
          this.closeDeleteFilterModal();
        },
        successMessage: this.$t('reactiveLogs.notificationDeletedSuccessfully')
      });
    },
    handleDeleteMultipleFilters() {
      const filterIdsToDelete = this.checkedFilterIds;

      reactiveLogsApi.deleteFilters({
        filterIds: filterIdsToDelete
      }, {
        onSuccess: () => {
          this.closeDeleteFiltersModal();
          this.resetCheckedFilters();
          this.getAllLogFilters();
        },
        onError: () => {
          this.closeDeleteFiltersModal();
        },
        successMessage: this.$t('reactiveLogs.notificationDeletedSuccessfully')
      });
    },
    downloadReport(url, fileName) {
      reactiveLogsApi.downloadReport({ url, fileName });
    },
    startDebug() {
      if (this.isStartButtonDisabled) {
        return;
      }
      reactiveLogsApi.switchReactiveLogs({
        newReactiveLogsState: this.REACTIVE_LOGS_STATES.start
      },
      {
        onSuccess: ({ updatedReactiveLogsState }) => {
          setTimeout(() => {
            VueNotifications.info({ message: this.$t('reactiveLogs.replicateTheIssueNowHint') });
          }, 2000);
          this.reactiveLogsState = updatedReactiveLogsState;
        },
        successMessage: this.$t('reactiveLogs.notificationStartedSuccessfully')
      });
    },
    stopDebug() {
      if (this.isStopButtonDisabled) {
        return;
      }

      reactiveLogsApi.switchReactiveLogs({
        newReactiveLogsState: this.REACTIVE_LOGS_STATES.stop
      },
      {
        onSuccess: ({ updatedReactiveLogsState }) => {
          setTimeout(() => {
            VueNotifications.info({ message: this.$t('reactiveLogs.nowClickGenerateToGetGatheredDataHint') });
          }, 2000);
          this.reactiveLogsState = updatedReactiveLogsState;
        },
        successMessage: this.$t('reactiveLogs.notificationStoppedSuccessfully')
      });
    }
  },
  watch: {},
  created() {
    this.REACTIVE_LOGS_STATES = REACTIVE_LOGS_STATES;
  },
  mounted() {
    this.getAllLogFilters();
  }
};
</script>

<style lang="css" scoped>
.card.left {
  flex: 1 1 60%;
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.2s linear;
}

.v-enter,
.v-leave-to {
  flex: 1 1 0%;
}
</style>
