/**
 * API для реактивных логов.
 * @module reactiveLogsApi
*/

import getLogFilters from './methods/getLogFilters';
import generateReactiveLog from './methods/generateReactiveLog';
import addFilters from './methods/addFilters';
import updateFilters from './methods/updateFilters';
import deleteFilters from './methods/deleteFilters';
import switchReactiveLogs from './methods/switchReactiveLogs';
import downloadReport from './methods/downloadReport';

const reactiveLogsApi = {
  getLogFilters,
  generateReactiveLog,
  addFilters,
  updateFilters,
  deleteFilters,
  switchReactiveLogs,
  downloadReport
};

export default reactiveLogsApi;
