// миксин c логикой для детектирования и извлечения переденного через роут при редиректе мак адреса клиента
// используется локально в разделе с логами

const CLIENT_MAC_ADDR_PARAM_NAME = 'clientMacAddr'; // имя передаваемого параметра

const workWithPassedByRouterParamClientMACMixin = {
  computed: {
    /**
     * Возвращает значение переданного через параметры роута клиентского MACa или undefined если такой параметр не передан
     *
     * @return {string|undefined}
     */
    passedByRouterParamClientMACValue() {
      return this.$route.params[CLIENT_MAC_ADDR_PARAM_NAME];
    }
  }
};

export default workWithPassedByRouterParamClientMACMixin;
