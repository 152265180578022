/**
 * Преобразовывает сырые логи в формат, удобный для отображения.
 *
 * @param {Array} rawLogs - Массив сырых логов.
 * @returns {Array} - Массив преобразованных логов.
*/

import { v4 as uuidv4 } from 'uuid';

const transformLogs = (rawLogs) => {
  const transformedLogs = [];

  rawLogs.forEach((item) => {
    const labels = [];
    Object.keys(item.stream).forEach((label) => {
      labels.push({
        name: label,
        value: item.stream[label]
      });
    });
    item.values.forEach(([timeStamp, value]) => {
      const date = moment(parseInt(timeStamp, 10) / 1000000).format('YYYY-MM-DD HH:mm:ss');
      transformedLogs.push({
        id: uuidv4(),
        date,
        value,
        labels
      });
    });
  });

  return transformedLogs;
};

export default transformLogs;
