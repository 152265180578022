/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_ADD_DEVICES_URL } from '../config';

/**
 * Функция-фетчер для отправки запроса на добавление реактивных лог-фильтров для указанных mac ip устройств.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {string[]} settings.ipMACs - Массив mac ip устройств для добавления.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const postAddFiltersFetcher = async (settings) => {
  const { ipMACs } = settings;

  if (!Array.isArray(ipMACs)) {
    throw new Error('settings with ipMACs array prop must be set');
  }

  const items = ipMACs.reduce((acc, curr, index) => {
    acc[index] = { mac_ip: curr };
    return acc;
  }, {});

  const addDevicesQuery = {
    action: 'C',
    items
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_ADD_DEVICES_URL, addDevicesQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postAddDevicesFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {string[]} settings.ipMACs - Массив mac ip устройств для добавления.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const addFilters = async (settings, options) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }
    store.commit('setLoading');

    const response = await postAddFiltersFetcher(settings);
    const { data, status, description: errorDescription } = response;

    const { itemslist } = data;

    const addedDevices = itemslist[0];

    if (status === 'error') {
      throw new Error(errorDescription);
    }

    if (!addedDevices) {
      throw new Error('addFilters response returned body without payload');
    }

    if (status === 'success' && onSuccess) {
      onSuccess(addedDevices);
    }

    if (status === 'success') {
      store.commit('setSuccess', successMessage);
      return Promise.resolve(addedDevices);
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default addFilters;
