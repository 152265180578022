var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WTable',{staticStyle:{"height":"75vh"},attrs:{"max-height":"calc(75vh - 180px)","columns":_vm.columns,"rows":_vm.rows,"isDisabled":_vm.isDisabled,"bordered":false,"select-options":{
    enabled: true,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  },"search-options":{
    enabled: true,
    skipDiacritics: true,
    searchFn: _vm.globalSearchFn,
    placeholder: ((_vm.$t('general.search')) + " " + (_vm.$t('reactiveLogs.tableMACIP')))
  },"pagination-options":{
    enabled: true,
    remoteMode: true,
    mode: 'records', // pages, records
    position: 'bottom',
    perPage: 10,
    perPageDropdownEnabled: true,
    perPageDropdown: [5, 10, 20, 40, 50],
    dropdownAllowAll: true,
    jumpFirstOrLast : true,
    firstLabel: _vm.$t('general.pagination.firstPage'),
    lastLabel: _vm.$t('general.pagination.lastPage'),
    nextLabel: _vm.$t('general.pagination.nextPage'),
    prevLabel: _vm.$t('general.pagination.prevPage'),
    rowsPerPageLabel: _vm.$t('general.pagination.rowsPerPage'),
    pageLabel: _vm.$t('general.pagination.page'),
    ofLabel: _vm.$t('general.pagination.of'),
    allLabel: _vm.$t('general.pagination.all')
  },"isLoading":_vm.isDataLoading},on:{"update:isLoading":function($event){_vm.isDataLoading=$event},"update:is-loading":function($event){_vm.isDataLoading=$event},"update:columns":_vm.handleUpdateColumns,"on-selected-rows-change":_vm.handleSelectedRowsChange,"on-cell-click":_vm.handleCellClick,"on-page-change":_vm.handlePageChange,"on-per-page-change":_vm.handlePerPageChange},scopedSlots:_vm._u([{key:"table-menu",fn:function(){return [_vm._t("table-menu")]},proxy:true},{key:"table-row",fn:function(props){return [(props.column.field === 'file')?[(props.row.file.url)?_c('div',{staticClass:"d-flex"},[_c('ReactiveLogsTooltip',{attrs:{"fileName":props.row.file.filename,"href":_vm.API_URL + props.row.file.url,"startAt":props.row.file.start_at,"stopAt":props.row.file.stop_at,"url":props.row.file.url}})],1):_c('div',[_vm._v("-")])]:(props.column.field === 'action')?[_c('WButton',{attrs:{"success":"","sm":"","disabled":_vm.isDisabled},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('on-generate-report', props.row.action.id)}}},[_vm._v(" "+_vm._s(_vm.$t('reactiveLogs.generateButton'))+" ")])]:_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }