/* eslint-disable consistent-return */

import store from '@/store';

import { API_URL } from '@/config';
import { downloadTxtFile } from '../utils';
import { AXIOS_INSTANCE } from '../config';

/**
 * Функция-фетчер для загрузки отчета в формате blob.
 *
 * @param {Object} url - url для скачивания отчета.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const reportFetcher = async (url) => {
  if (!url) {
    throw new Error('url prop must be set');
  }

  try {
    const response = await AXIOS_INSTANCE({
      url: `${API_URL}${url}`,
      method: 'GET',
      responseType: 'blob'
    });

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера reportFetcher.
 * обрабатывает тело запроса и скачивает файл.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {any} settings.url - url для скачивания отчета.
 * @param {Boolean} settings.fileName - название файла.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const downloadReport = async (settings) => {
  const { url, fileName } = settings;

  try {
    store.commit('setLoading');

    const data = await reportFetcher(url);

    await downloadTxtFile(data, fileName);

    store.commit('setSuccess');

    return Promise.resolve();
  } catch (error) {
    store.commit('setError', error);
  }
};

export default downloadReport;
