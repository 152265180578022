var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WTable',{staticStyle:{"height":"75vh"},attrs:{"max-height":"calc(75vh - 90px)","columns":_vm.columns,"rows":_vm.rows,"isDisabled":_vm.isDisabled,"bordered":false,"sort-options":{
    enabled: false,
  },"group-options":{
    enabled: true,
    collapsable: true // or column index
  },"pagination-options":{
    enabled: false,
    remoteMode: true,
    mode: 'pages', // pages, records
    position: 'bottom',
    perPage: 40,
    perPageDropdownEnabled: true,
    perPageDropdown: [40, 80],
    dropdownAllowAll: true,
    jumpFirstOrLast : true,
    firstLabel: _vm.$t('general.pagination.firstPage'),
    lastLabel: _vm.$t('general.pagination.lastPage'),
    nextLabel: _vm.$t('general.pagination.nextPage'),
    prevLabel: _vm.$t('general.pagination.prevPage'),
    rowsPerPageLabel: _vm.$t('general.pagination.rowsPerPage'),
    pageLabel: _vm.$t('general.pagination.page'),
    ofLabel: _vm.$t('general.pagination.of'),
    allLabel: _vm.$t('general.pagination.all')
  },"isLoading":_vm.isDataLoading},on:{"update:isLoading":function($event){_vm.isDataLoading=$event},"update:is-loading":function($event){_vm.isDataLoading=$event},"update:columns":_vm.handleUpdateColumns,"on-page-change":_vm.handlePageChange,"on-per-page-change":_vm.handlePerPageChange},scopedSlots:_vm._u([{key:"table-menu",fn:function(){return [_vm._t("table-menu")]},proxy:true},{key:"table-header-row",fn:function(props){return [(props.column.field === 'date')?[_c('span',{staticClass:"logs-table__header-row date"},[_vm._v(" "+_vm._s(props.row.date)+" ")])]:_vm._e(),(props.column.field === 'log')?[_c('span',{staticClass:"logs-table__header-row log"},[_vm._v(" "+_vm._s(props.row.log)+" ")])]:_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'log')?[_c('ul',{staticClass:"logs-table__row additional"},_vm._l((props.row.log),function(item){return _c('li',{key:item.id},[_c('span',[_c('strong',[_vm._v(_vm._s(((item.name) + ": ")))])]),_c('span',[_vm._v(" "+_vm._s(item.value)+" ")])])}),0)]:_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }