/**
 * конфигурация таблицы LogFiltersTable
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/
function generateTableColumns({ i18nInstance, hiddenColumnRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('reactiveLogs.tableMACIP'),
      field: 'mac_ip',
      width: 'auto',
      hidden: hiddenColumnRules.mac_ip
    },
    {
      label: $t('reactiveLogs.tableTraceFile'),
      field: 'file',
      sortable: false,
      width: '25%',
      hidden: hiddenColumnRules.file
    },
    {
      label: $t('reactiveLogs.tableAction'),
      field: 'action',
      sortable: false,
      width: '25%',
      hidden: hiddenColumnRules.action
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ logFiltersData }) {
  return logFiltersData.map(log => {
    const {
      // eslint-disable-next-line camelcase
      mac_ip, url, filename, start_at, stop_at, id
    } = log;
    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: log,
      // данные для строк по столбцу mac ip
      mac_ip,
      // данные для строк по столбцу file
      file: {
        id,
        url,
        filename,
        start_at,
        stop_at
      },
      // данные для строк по столбцу action
      action: {
        id
      }
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
