<template>
  <WTable
    style="height: 75vh"
    max-height="calc(75vh - 90px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :sort-options="{
      enabled: false,
    }"
    :group-options="{
      enabled: true,
      collapsable: true // or column index
    }"
    :pagination-options="{
      enabled: false,
      remoteMode: true,
      mode: 'pages', // pages, records
      position: 'bottom',
      perPage: 40,
      perPageDropdownEnabled: true,
      perPageDropdown: [40, 80],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
  >

    <template v-slot:table-menu>
        <slot name="table-menu"></slot>
    </template>

    <template v-slot:table-header-row="props">
      <template v-if="props.column.field === 'date'">
        <span class="logs-table__header-row date">
          {{ props.row.date }}
        </span>
      </template>

      <template v-if="props.column.field === 'log'">
        <span class="logs-table__header-row log">
          {{ props.row.log }}
        </span>
      </template>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'log'">
        <ul class="logs-table__row additional">
          <li v-for="item in props.row.log" :key="item.id">
            <span>
              <strong>{{ `${item.name}: ` }}</strong>
            </span>
            <span>
              {{ item.value }}
            </span>
          </li>
        </ul>
      </template>
    </template>
  </WTable>
</template>

<script>
/**
 * компонент - таблица логов.
 * компонент локальный: используется только в Logs.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import {
  generateTableColumns,
  generateTableRows
} from './config';

export default {
  name: 'LogsTable',
  components: {
  },
  props: {
    logs: {
      type: Array,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        date: false,
        log: false
      }
    };
  },
  watch: {
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            date: this.isTableColumnHidden.date,
            log: this.isTableColumnHidden.log
          }
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        logsData: this.logs
      });
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    }
  }
};
</script>

<style lang="css" scoped>
.logs-table__header-row {
  font-weight: 400;
}

.logs-table__header-row.date {
  color: var(--brand-primary);
}

.logs-table__header-row.log {
  word-break: break-all;
}

.logs-table__row.additional {
  margin: 0;
  padding: 0;
}

.logs-table__row.additional > li:not(:last-of-type) {
  margin-bottom: 4px;
}
</style>
