/**
 * Конвертирует миллисекунды в секунды и округляет в меньшую сторону (чтобы всегда были целые числа).
 * @param {number} ms - Время в миллисекундах.
 * @returns {number} - Время в секундах.
*/
const convertMillisecondsToSeconds = (ms) => Math.floor(ms / 1000);

/**
 * Рассчитывает интервал в секундах между двумя временными метками.
 * @param {number} startTimestamp - Время начала интервала в миллисекундах.
 * @param {number} endTimestamp - Время окончания интервала в миллисекундах.
 * @returns {number} - Интервал в секундах.
 */
const getIntervalInSeconds = (startTimestamp, endTimestamp) => {
  return convertMillisecondsToSeconds(endTimestamp) - convertMillisecondsToSeconds(startTimestamp);
};

/**
 * Асинхронно загружает текстовый файл в браузере и предоставляет его для скачивания.
 *
 * @param {string} data - Текстовые данные, которые должны быть сохранены в файле.
 * @param {string} fileName - Имя файла, под которым он будет скачан (необязательно).
 * @returns {Promise<void>} Промис, который разрешается после успешной загрузки файла.
 * @throws {Error} Если параметр `data` отсутствует.
 */
const downloadTxtFile = async (data, fileName) => {
  if (!data) {
    throw new Error('report download error');
  }

  // Создание объекта Blob для текстового файла
  const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });

  // Генерация URL для скачивания файла
  const url = window.URL.createObjectURL(blob);

  // Создание ссылки для скачивания файла
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName ?? 'document.txt';

  // Эмуляция клика на ссылку для скачивания
  a.click();

  // Освобождение ресурсов
  window.URL.revokeObjectURL(url);

  return Promise.resolve();
};

export {
  convertMillisecondsToSeconds,
  getIntervalInSeconds,
  downloadTxtFile
};
