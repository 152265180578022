<template>
  <Transition>
  <div class="card ml-2" v-show="isOpen">
    <div class="card-header">
      <WButton secondary outline sm
        customClass="float-right"
        :disabled="isLoading"
        @click="handleClose"
      >
        <i class="fa fa-close"/>
      </WButton>
    </div>
    <div class="card-block">
      <div class="row">
        <loader-spinner-small centered v-if="isLoading"/>
        <dd class="col-6">
          {{ $t('reactiveLogs.logState') }}
        </dd>
        <dd class="col-6" v-if="isLoading">
          {{ $t('reactiveLogs.logStateInProgress') }}
        </dd>
        <dd class="col-6" v-if="isSuccess">
          <span class="badge badge-success">{{ $t('reactiveLogs.logStateSuccessful') }}</span>
        </dd>
        <dd v-if="isError" class="col-6">
          —
        </dd>
      </div>
      <div class="row">
        <dd class="col-6">{{ $t('reactiveLogs.tableMACIP') }}</dd>
        <dd class="col-6">{{ isLoading ? '-' : generatingResultData.mac_ip }}</dd>
      </div>
      <div class="row">
        <dd class="col-6">
          {{ $t('reactiveLogs.startTime') }}
        </dd>
        <dd class="col-6">
          {{ isLoading ? '-' : formatDatetime(generatingResultData.start_at) }}
        </dd>
      </div>
      <div class="row">
        <dd class="col-6">
          {{ $t('reactiveLogs.endTime') }}
        </dd>
        <dd class="col-6">
          {{ isLoading ? '-' : formatDatetime(generatingResultData.stop_at) }}
        </dd>
      </div>
      <div class="row file-name-row">
        <dd class="col-6">
          {{ $t('reactiveLogs.tableTraceFile') }}
        </dd>
        <dd class="col-6" v-if="!isLoading && generatingResultData.url">
          <span class="ml-q"
            style="display: inline-block; top: 10px"
            role="button"
            @click.stop.prevent="downloadReport(generatingResultData.url, generatingResultData.filename)">
            <a :href="`${generatingResultData.url}`" class="fa fa-download mr-h"></a>
          </span>
        </dd>
        <dd v-else class="col-6">
          <span>—</span>
        </dd>
      </div>
    </div>
  </div>
  </Transition>
</template>

<script>
/**
 * компонент - карточка-статус генерации реактивных логов.
 * компонент локальный: используется только в ReactiveLogs.vue
 * @component
 */

import reactiveLogsApi from '../../api';
import { API_URL } from '@/config';

export default {
  name: 'ReactiveLogsGeneratingStatusCard',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isSuccess: {
      type: Boolean,
      required: true
    },
    isError: {
      type: Boolean,
      required: true
    },
    /**
     * Объект, представляющий данные с результатами генерации логов.
     * @type {Object}
     * @property {string} filename - Имя файла.
     * @property {string} id - Идентификатор.
     * @property {string} mac_ip - MAC/IP адрес.
     * @property {number} start_at - Время начала.
     * @property {number} stop_at - Время окончания.
     * @property {string} url - URL.
     */
    generatingResultData: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDatetime(timestamp) {
      return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
    },
    openInNewTab(url) {
      window.open(API_URL + url, '_blank');
    },
    downloadReport(url, fileName) {
      reactiveLogsApi.downloadReport({ url, fileName });
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="css" scoped>
.file-name-row {
  word-break: break-all;
  word-wrap: break-word;
}

.card{
  flex: 1 0 30%;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.2s linear;
}

.v-enter,
.v-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
