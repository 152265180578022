// Тут задаем список элементов фильтра, которые не должны отображатся в WLC / в ONE.
// В дальнейшем они просто вырезаются из списка в зависимости от сборки в
// src/views/Monitor/Logs/Logs.vue
// Влияет как на элементы пришедшие с бэка из api-logger/loki/api/v1/label/group/values
// Так и на захардкоженные в src/views/Monitor/Logs/config/containers.js элементы

// Появилось в рамках задачи https://wimark.kaiten.ru/space/327612/card/35339305

const NOT_DISPALYED_FILTER_ITEMS_FOR_WLC = ['portal'];

// NOT_DISPALYED_FILTER_ITEMS_FOR_ONE  если понадобится заполняется по такому же принципу
// как и NOT_DISPALYED_FILTER_ITEMS_FOR_WLC
// на данный момент пустой, но вполне рабочий
const NOT_DISPALYED_FILTER_ITEMS_FOR_ONE = [];

export { NOT_DISPALYED_FILTER_ITEMS_FOR_WLC, NOT_DISPALYED_FILTER_ITEMS_FOR_ONE };
