/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_REACTIVE_LOGS_STATE_URL } from '../config';

/**
 * Функция-фетчер для отправки запроса на включение/отключение сбора реактивных логов.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {'start' | 'stop'} settings.newReactiveLogsState - включить/выключить режим сбора реактивных логов.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const postReactiveLogsStateFetcher = async (settings) => {
  const { newReactiveLogsState } = settings;

  if (newReactiveLogsState !== 'start' && newReactiveLogsState !== 'stop') {
    throw new Error("prop newReactiveLogsState must be set as 'start' of 'stop' value");
  }

  const reactiveLogsStateQuery = {
    action: 'U',
    items: {
      0: {
        troubleshooting_schema: {
          state: newReactiveLogsState
        }
      }
    }
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_REACTIVE_LOGS_STATE_URL, reactiveLogsStateQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postReactiveLogsStateFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {'start' | 'stop'} settings.newReactiveLogsState - включить/выключить режим сбора реактивных логов.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const switchReactiveLogs = async (settings, options) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }
    store.commit('setLoading');

    const response = await postReactiveLogsStateFetcher(settings);
    const { data, status, description: errorDescription } = response;

    const { itemslist } = data;

    const deviceFiltersList = itemslist[0].troubleshooting_filters;
    const updatedReactiveLogsState = itemslist[0].state;

    if (status === 'error') {
      throw new Error(errorDescription);
    }

    if (!updatedReactiveLogsState || !deviceFiltersList) {
      throw new Error('switchReactiveLogs response returned body without payload');
    }

    if (status === 'success' && onSuccess) {
      onSuccess({
        deviceFiltersList,
        updatedReactiveLogsState
      });
    }

    if (status === 'success') {
      store.commit('setSuccess', successMessage);
      return Promise.resolve({
        deviceFiltersList,
        updatedReactiveLogsState
      });
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default switchReactiveLogs;
