/* eslint-disable consistent-return */
/**
 * api для загрузки логов.
 */

import { AXIOS_INSTANCE } from '@/api';
import store from '@/store';
import { API_URL } from '@/config';
import { PREDEFINED_CONTAINERS } from './config';

/**
 * Асинхронная функция для загрузки данных лейблов.
 *
 * @param {string} label - Имя лейбла.
 * @returns {Promise<Object>} - Обещание с данными меток.
 * @throws {Error} - Ошибка при загрузке меток.
 */
const labelsDataFetcher = async (label) => {
  try {
    const response = await AXIOS_INSTANCE.post(`${API_URL}/api-logger/loki/api/v1/label/${label}/values`);
    const labels = response.data;

    return Promise.resolve(labels);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Асинхронная функция для загрузки данных логов.
 *
 * @param {Object} params - Параметры запроса.
 * @param {string} params.searchText - Текст поиска.
 * @param {string} params.searchContainer - Контейер поиска.
 * @param {number} params.limit - Максимальное количество записей.
 * @param {number} params.startDateTimestamp - timestamp начальной даты.
 * @param {number} params.endDateTimestamp - timestamp конечной даты.
 * @returns {Promise<Object>} - Обещание с данными логов.
 * @throws {Error} - Ошибка при загрузке логов.
 */
const logsDataFetcher = async ({
  searchText,
  searchContainer,
  limit,
  startDateTimestamp,
  endDateTimestamp
}) => {
  const PREDEFINED_CONTAINERS_NAMES = Object.keys(PREDEFINED_CONTAINERS);
  let query = '';

  if (searchContainer === '') {
    query = '{group=~".+"}';
  } else if (PREDEFINED_CONTAINERS_NAMES.includes(searchContainer)) {
    // для предопределенных контейнеров свои захардкоженные значения query, задаваемые в конфиге PREDEFINED_CONTAINERS
    query = PREDEFINED_CONTAINERS[searchContainer].request_params.query;
  } else {
    query = `{group="${searchContainer}"}`;
  }

  if (searchText.trim() !== '') {
    query += ` |= "${searchText}"`;
  }

  // переводим милисекунды в наносекунды из-за формата в локи
  // https://grafana.com/docs/loki/latest/reference/loki-http-api/#timestamps
  const startTimeNanoseconds = startDateTimestamp * 1000000;
  const endTimeNanoseconds = endDateTimestamp * 1000000;

  const urlForRequest = `${API_URL}/api-logger/loki/api/v1/query_range?query=${encodeURIComponent(query)}&limit=${limit}&start=${startTimeNanoseconds}&end=${endTimeNanoseconds}`;

  try {
    const response = await AXIOS_INSTANCE.post(urlForRequest);
    const logs = response.data;

    return Promise.resolve(logs);
  } catch (error) {
    return Promise.reject(error);
  }
};

const logsApiService = {
  /**
   * Асинхронно загружает логи с сервера.
   *
   * @param {Object} params - Параметры запроса.
   * @param {string} params.searchText - Текст поиска.
   * @param {string} params.searchContainer - Контейнер поиска.
   * @param {number} params.limit - Максимальное количество записей.
   * @param {number} params.startDateTimestamp - timestamp начальной даты.
   * @param {number} params.endDateTimestamp - timestamp конечной даты.
   * @param {Object} [options] - Дополнительные опции запроса.
    * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
    * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
    * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
   * @returns {Promise<Object>} - Обещание с данными логов.
   * @throws {Error} - Ошибка при загрузке логов.
   */
  fetchLogs: async (params, options = {
    onLoading: undefined,
    onSuccess: undefined,
    onError: undefined
  }) => {
    const {
      onLoading,
      onSuccess,
      onError
    } = options;

    try {
      if (onLoading) {
        onLoading();
      }

      store.commit('setLoading');
      const response = await logsDataFetcher(params);
      const { data, status } = response;

      if (status === 'success' && onSuccess) {
        onSuccess(data);
      }

      if (status === 'success') {
        store.commit('setSuccess');
        return data;
      }

      throw new Error('Logs cannot be loaded');
    } catch (error) {
      if (onError) {
        onError(error);
      }
      store.commit('setError', error);
    }
  },

  /**
   * Асинхронно загружает лейблы с сервера.
   *
   * @param {string} label - Имя метки лейбла.
   * @returns {Promise<Object>} - Обещание с данными лейблов.
   * @throws {Error} - Ошибка при загрузке лейблов.
   */

  fetchLabels: async (label) => {
    try {
      store.commit('setLoading');
      const response = await labelsDataFetcher(label);
      const { data, status } = response;

      if (status === 'success') {
        store.commit('setSuccess');
        return data;
      }

      throw new Error('labels cannot be loaded');
    } catch (error) {
      store.commit('setError', error);
    }
  }
};

export default logsApiService;
