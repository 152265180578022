<template>
  <WModalContent
    :isOpen="isOpen"
    @close="handleClose"
  >
    <template #header>
      <h4 class="heading">
        {{ $t('reactiveLogs.logsSettingsModal') }}
      </h4>
    </template>
    <template #body>
      <div class="body">
        <p>{{ $t('reactiveLogs.generateLogsFor') }}:</p>
        <div class="form-container">
          <WRadio
            :label="$t('reactiveLogs.generateLogsFor10Minutes')"
            @input="setData(RADIO_OPTIONS_CONFIG['10min'].label)"
            name="time"
            id="10min"
            value="10min"
            :checked="data.rangeName === '10min'"
          />
          <WRadio
            :label="$t('reactiveLogs.generateLogsFor20Minutes')"
            @input="setData(RADIO_OPTIONS_CONFIG['30min'].label)"
            name="time"
            id="30min"
            value="30min"
            :checked="data.rangeName === '30min'"
          />
          <WRadio
            :label="$t('reactiveLogs.generateLogsFor1Hour')"
            @input="setData(RADIO_OPTIONS_CONFIG['1hour'].label)"
            name="time"
            id="1hour"
            value="1hour"
            :checked="data.rangeName === '1hour'"
          />
            <!-- customTime -->
            <div class="input-container">
              <WRadio
                @input="setData(RADIO_OPTIONS_CONFIG['customTimeRange'].label)"
                name="time"
                id="customTimeRange"
                value="customTimeRange"
                :checked="data.rangeName === 'customTimeRange'"
              />
              <div class="inputs-wrapper">
                <WInput
                  type="number"
                  v-model.number="customTimeRangeInputs.amount"
                  @focus="setData(RADIO_OPTIONS_CONFIG['customTimeRange'].label)"
                  id="customTimeRange"
                  name="customTimeRange"
                  v-validate="{ required: isValidateCustomTimeRangeInput }"
                  data-vv-validate-on="input"
                  :data-vv-as="$t('reactiveLogs.interval')"
                  :errorText="errors.first('customTimeRange')"
                />
                <WSelect
                  id="customTimeRangeInput"
                  @focus="setData(RADIO_OPTIONS_CONFIG['customTimeRange'].label)"
                  v-model="customTimeRangeInputs.units"
                  :options="selectTimeOptions"
                />
            </div>
          </div>
          <!-- datepicker -->
          <div class="input-container">
            <WRadio
              @input="setData(RADIO_OPTIONS_CONFIG['customDatetimeRange'].label)"
              name="time"
              id="customDatetimeRange"
              value="customDatetimeRange"
              :checked="data.rangeName === 'customDatetimeRange'"
            />
            <div class="inputs-wrapper">
              <WDatetimeInput
                id="customDatetimeRange"
                name="customDatetimeRange"
                :label="`${$t('reactiveLogs.generateLogsForCustomDatetime')}:`"
                v-model="customDatetimeRangeInputs"
                clearable
                :disabledDate="setDisabledDatepickerDatetime"
                :disabledTime="setDisabledDatepickerDatetime"
                :errorText="errors.first('customDatetimePicker-validation')"
              />
              <!-- скрытый инпут для валидации datepicker"-->
              <input
                style="display: none;"
                v-model.number="customDatetimeRangeInputs.endDate"
                type="number"
                name="customDatetimePicker-validation"
                v-validate="{ required: isValidateCustomDatetimeRangeDatepicker }"
                data-vv-validate-on="input"
                :data-vv-as="$t('reactiveLogs.interval')"
              />
            </div>
      </div>
    </div>

      </div>
    </template>
    <template #footer>
      <div class="button-section">
        <WButton secondary outline @click="handleClose">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton success outline @click="handleSubmit">
          {{ $t('reactiveLogs.generateButton') }}
        </WButton>
      </div>
    </template>
  </WModalContent>
</template>

<script>
/**
 * компонент - модалка для генерации параметров выгрузки реактивных логов.
 * компонент локальный: используется только в ReactiveLogs.vue
 * @component
 */

import { CUSTOM_INTERVALS_UNITS_CONFIG, RADIO_OPTIONS_CONFIG } from './config';

export default {
  name: 'GenerateReactiveLogsModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isOpen(newValue) {
      if (!newValue) {
        this.resetData();

        return;
      }

      this.setInitialData();
    },
    customTimeRangeInputs: {
      handler() {
        // Вызов функции при изменении любого поля в customTimeRangeInputs
        this.setData('customTimeRange');
      },
      deep: true
    },
    customDatetimeRangeInputs: {
      handler() {
        // Вызов функции при изменении любого поля в datepicker
        this.setData('customDatetimeRange');
      },
      deep: true
    }
  },
  data() {
    return {
      data: {
        dateRange: {
          startDate: null,
          endDate: null
        },
        rangeName: ''
      },
      // контролируемые инпуты для кастомного выбора времени при формировании отчета
      customTimeRangeInputs: {
        amount: 15,
        units: CUSTOM_INTERVALS_UNITS_CONFIG.minutes.label
      },
      // контролируемые инпуты для кастомного выбора даты и времени при формировании отчета
      customDatetimeRangeInputs: {
        startDate: null,
        endDate: null
      }
    };
  },
  computed: {
    isValidateCustomTimeRangeInput() {
      const isCustomTimeRangeInputSelected = this.data.rangeName === this.RADIO_OPTIONS_CONFIG.customTimeRange.label;
      return isCustomTimeRangeInputSelected;
    },
    isValidateCustomDatetimeRangeDatepicker() {
      const isDatepickerSelected = this.data.rangeName === this.RADIO_OPTIONS_CONFIG.customDatetimeRange.label;
      return isDatepickerSelected;
    },
    selectTimeOptions() {
      return Object.keys(CUSTOM_INTERVALS_UNITS_CONFIG).map(unit => ({
        data: unit,
        display: this.$t(`reactiveLogs.customIntervalsUnits.${unit}`)
      }));
    }
  },
  methods: {
    setData(option) {
      switch (option) {
        case this.RADIO_OPTIONS_CONFIG['10min'].label:
          this.data = {
            dateRange: {
              startDate: this.RADIO_OPTIONS_CONFIG['10min'].getStartDate(),
              endDate: this.RADIO_OPTIONS_CONFIG['10min'].getEndDate()
            },
            rangeName: this.RADIO_OPTIONS_CONFIG['10min'].label
          };
          break;
        case this.RADIO_OPTIONS_CONFIG['30min'].label:
          this.data = {
            dateRange: {
              startDate: this.RADIO_OPTIONS_CONFIG['30min'].getStartDate(),
              endDate: this.RADIO_OPTIONS_CONFIG['30min'].getEndDate()
            },
            rangeName: this.RADIO_OPTIONS_CONFIG['30min'].label
          };
          break;
        case this.RADIO_OPTIONS_CONFIG['1hour'].label:
          this.data = {
            dateRange: {
              startDate: this.RADIO_OPTIONS_CONFIG['1hour'].getStartDate(),
              endDate: this.RADIO_OPTIONS_CONFIG['1hour'].getEndDate()
            },
            rangeName: this.RADIO_OPTIONS_CONFIG['1hour'].label
          };
          break;
        case this.RADIO_OPTIONS_CONFIG.customTimeRange.label:
          this.data = {
            dateRange: {
              startDate: this.RADIO_OPTIONS_CONFIG.customTimeRange.getStartDate(this.customTimeRangeInputs),
              endDate: this.RADIO_OPTIONS_CONFIG.customTimeRange.getEndDate()
            },
            rangeName: this.RADIO_OPTIONS_CONFIG.customTimeRange.label
          };
          break;
        case this.RADIO_OPTIONS_CONFIG.customDatetimeRange.label:
          this.data = {
            dateRange: {
              startDate: this.customDatetimeRangeInputs.startDate, // start timestamp даты и времени из datepicker
              endDate: this.customDatetimeRangeInputs.endDate // end timestamp даты и времени из datepicker
            },
            rangeName: this.RADIO_OPTIONS_CONFIG.customDatetimeRange.label
          };
          break;
        default:
          console.error('error in setData: invalid option prop');
          break;
      }
    },
    setInitialData() {
      this.data = {
        dateRange: {
          startDate: this.RADIO_OPTIONS_CONFIG['10min'].getStartDate(),
          endDate: this.RADIO_OPTIONS_CONFIG['10min'].getEndDate()
        },
        rangeName: this.RADIO_OPTIONS_CONFIG['10min'].label
      };
    },
    resetData() {
      this.data = {
        dateRange: {
          startDate: null,
          endDate: null
        },
        rangeName: ''
      };

      this.customTimeRangeInputs = {
        amount: 15,
        units: CUSTOM_INTERVALS_UNITS_CONFIG.minutes.label
      };

      this.customDatetimeRangeInputs = {
        startDate: null,
        endDate: null
      };
    },
    setDisabledDatepickerDatetime(date) {
      const currentDateTimestamp = Date.now();

      // disable даты и времени в datepicke, если они больше текущего времени
      return date > currentDateTimestamp;
    },
    async handleSubmit() {
      const isFormValid = await this.checkFormValidity();

      if (!isFormValid) {
        return;
      }

      const { dateRange } = this.data;

      this.$emit('sumbit', dateRange);
    },
    handleClose() {
      this.$emit('close');
    },
    async checkFormValidity() {
      const isFormValid = await this.$validator.validateAll();

      return isFormValid;
    }
  },
  created() {
    this.CUSTOM_INTERVALS_UNITS_CONFIG = CUSTOM_INTERVALS_UNITS_CONFIG;
    this.RADIO_OPTIONS_CONFIG = RADIO_OPTIONS_CONFIG;
  }
};
</script>

<style lang="css" scoped>
  h4 {
    margin: 0;
  }

  .body {
    display: flex;
    gap: 16px;
  }

  .body > *:first-child {
    flex: 0 0 33%;
  }

  .body > *:last-child {
    flex: 1 1 auto;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form-container > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .input-container {
    display: flex;
    align-items: center;
  }

  .input-container > *:not(:last-child) {
    margin-right: 16px;
  }

  .inputs-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
  }

  .inputs-wrapper > * {
    flex: 1 1 50%;
  }
</style>
