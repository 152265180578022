// настройки связаные с контейнерами

/**
 * Объект содержит имена предустановленных контейнеров и параметры для запроса данных по ним
 * Ключи - имена для отображения в UI, значения - объект с параметрами для запроса чтобы получить нужные данные
 * Предуставновленные имена контейнеров примешиваются к приходящим с бэка из ручки
 * api-logger/loki/api/v1/label/group/values именам и видны фильтре "Фильтр"
 */
const PREDEFINED_CONTAINERS = {
  'cpu-utilization': {
    request_params: {
      query: '{group="controller"} |~ "CPU_UTIL-(CRITICAL|WARNING)"'
    }
  },

  'ram-utilization': {
    request_params: {
      query: '{group="controller"} |~ "RAM_UTIL-(CRITICAL|WARNING)"'
    }
  },

  'disk-utilization': {
    request_params: {
      query: '{group="controller"} |~ "DISK_UTIL-(CRITICAL|WARNING)"'
    }
  },
  'net-utilization': {
    request_params: {
      query: '{group="controller"} |~ "NET_UTIL-(CRITICAL|WARNING)"'
    }
  },

  'user-login': {
    request_params: {
      query: '{group="controller"} |~ "SEC_LOGIN-INFO"'
    }
  },

  'ap-status': {
    request_params: {
      query: '{group="controller"} |~ "AP_STATUS-INFO"'
    }
  },

  'client-status': {
    request_params: {
      query: '{group="controller"} |~ "CLIENT_STATUS-INFO"'
    }
  },

  'ap-channel': {
    request_params: {
      query: '{group="controller"} |~ "AP_CHANNEL_CHANGE-INFO"'
    }
  },

  'ap-power': {
    request_params: {
      query: '{group="controller"} |~ "AP_POWER_CHANGE-INFO"'
    }
  },
  'client-psk-mismatch': {
    request_params: {
      query: '{group="controller"} |~ "CLIENT_PSK_MISMATCH"'
    }
  }
};

export {
  PREDEFINED_CONTAINERS
};
