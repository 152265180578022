// миксин c логикой автообноления диапазона дат в инпуте выбора временного диапазона логов.
// ! миксин локальный. Используется только в компоненте ControlPanel.vue

import {
  getDefaultFormValues
} from '../config';

const datetimeInputIntervalUpdateMixin = {
  data() {
    return {
      // флаг, переключал ли пользователь дату в дейтпикере
      // нужен, чтобы запустить/остановить логику автообновления диапазона дат на актуальные
      // UX-логика: если юзер не трогал дейтпикер, диапазон дат обновляется каждую секунду.
      // Если установил кастомный диапазон, автообновление отключается.
      isDirtyDatetimeInput: false,
      // метка интервала
      datetimeInputUpdateId: undefined
    };
  },
  methods: {
    startDatetimeInputIntervalUpdate() {
      if (this.isDirtyDatetimeInput) {
        return;
      }

      if (this.datetimeInputUpdateId) {
        clearInterval(this.datetimeInputUpdateId);
        this.datetimeInputUpdateId = undefined;
      }

      const updateUnterval = setInterval(() => {
        // Обновляем диапазон дат каждую секунду,
        // чтобы он был всегда актуальным автоматически
        const defaultFormValues = getDefaultFormValues();

        this.datepickerForceSetValue = {
          startDate: defaultFormValues.startDate,
          endDate: defaultFormValues.endDate
        };
      }, 1000);

      this.datetimeInputUpdateId = updateUnterval;
    },
    stopDatetimeInputIntervalUpdate() {
      if (!this.datetimeInputUpdateId) {
        return;
      }
      clearInterval(this.datetimeInputUpdateId);
      this.datetimeInputUpdateId = undefined;
    }
  }
};

export default datetimeInputIntervalUpdateMixin;
