// настройки для выгрузки логов

/**
 * Желаемый порядок столбцов для текстовой таблицы - эти столбцы будут в приоритете.
 * После них будут все остальные столбцы.
 *
 * @options date, id, labels, showLabels, value.
 *
 * @type {string[]}
 */
const TXT_LOGS_ORDER = ['date', 'labels', 'value'];

/**
 * скрытые столбцы
 *
 * @options date, id, labels, showLabels, value.
 *
 * @type {string[]}
 */
const TXT_LOGS_HIDDEN = ['showLabels', 'id'];

/**
 * Оформление ячеек текстовой таблицы в виде массива, например, ['[', ']'], где
 * первый элемент - левая граница ячейки, а второй элемент - правая граница ячейки.
 * Пример: [date], [id] ...
 *
 * @type {[string, string]}
 */
const TXT_LOGS_CELL_BORDERS = ['[', ']'];


export {
  TXT_LOGS_ORDER,
  TXT_LOGS_HIDDEN,
  TXT_LOGS_CELL_BORDERS
};
